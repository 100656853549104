* {
  margin: 0;
  padding: 0;
}

body, html {
  height: 100%;
}

body {
  background-color: black;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.page img {
  width: 90%;
  cursor: pointer;
}
/*

body {
  background-image: url("images/pagina.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}*/
